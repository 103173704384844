export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"stylesheet","href":"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"},{"rel":"stylesheet","href":"https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.css"},{"rel":"stylesheet","href":"https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick-theme.css"}],"style":[],"script":[{"src":"https://code.jquery.com/jquery-3.6.0.min.js","body":true},{"type":"text/javascript","src":"https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js","body":true}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'