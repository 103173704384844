import { default as detail_45productppBC3ldZGPMeta } from "C:/Users/HP/WebstormProjects/Autocolor/pages/detail-product.vue?macro=true";
import { default as indexsktygfwaDuMeta } from "C:/Users/HP/WebstormProjects/Autocolor/pages/index.vue?macro=true";
import { default as product_45listCBmNgL9T04Meta } from "C:/Users/HP/WebstormProjects/Autocolor/pages/product-list.vue?macro=true";
import { default as qui_45somme_45nousNhFY4n3JkXMeta } from "C:/Users/HP/WebstormProjects/Autocolor/pages/qui-somme-nous.vue?macro=true";
export default [
  {
    name: detail_45productppBC3ldZGPMeta?.name ?? "detail-product",
    path: detail_45productppBC3ldZGPMeta?.path ?? "/detail-product",
    meta: detail_45productppBC3ldZGPMeta || {},
    alias: detail_45productppBC3ldZGPMeta?.alias || [],
    redirect: detail_45productppBC3ldZGPMeta?.redirect || undefined,
    component: () => import("C:/Users/HP/WebstormProjects/Autocolor/pages/detail-product.vue").then(m => m.default || m)
  },
  {
    name: indexsktygfwaDuMeta?.name ?? "index",
    path: indexsktygfwaDuMeta?.path ?? "/",
    meta: indexsktygfwaDuMeta || {},
    alias: indexsktygfwaDuMeta?.alias || [],
    redirect: indexsktygfwaDuMeta?.redirect || undefined,
    component: () => import("C:/Users/HP/WebstormProjects/Autocolor/pages/index.vue").then(m => m.default || m)
  },
  {
    name: product_45listCBmNgL9T04Meta?.name ?? "product-list",
    path: product_45listCBmNgL9T04Meta?.path ?? "/product-list",
    meta: product_45listCBmNgL9T04Meta || {},
    alias: product_45listCBmNgL9T04Meta?.alias || [],
    redirect: product_45listCBmNgL9T04Meta?.redirect || undefined,
    component: () => import("C:/Users/HP/WebstormProjects/Autocolor/pages/product-list.vue").then(m => m.default || m)
  },
  {
    name: qui_45somme_45nousNhFY4n3JkXMeta?.name ?? "qui-somme-nous",
    path: qui_45somme_45nousNhFY4n3JkXMeta?.path ?? "/qui-somme-nous",
    meta: qui_45somme_45nousNhFY4n3JkXMeta || {},
    alias: qui_45somme_45nousNhFY4n3JkXMeta?.alias || [],
    redirect: qui_45somme_45nousNhFY4n3JkXMeta?.redirect || undefined,
    component: () => import("C:/Users/HP/WebstormProjects/Autocolor/pages/qui-somme-nous.vue").then(m => m.default || m)
  }
]