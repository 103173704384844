<template>
    <div>
        <div class="search-bar" v-if="$config.public.underConstruction === false">
            <div class="search">
                <img src="/images/search-icon/Groupe 1092@2x.png" class="iconSearch" /> <span>Recherche</span>
            </div>
            <div class="cart">
                <img src="/images/cart-icon/Groupe 1127.png" class="iconCart" />
            </div>
            <div class="become-pro">
                <div>
                    <img src="/images/become-pro-icon/Groupe 625.png" /> 
                </div>
                <span>PRO</span>
            </div>
        </div>
        <div class="header" :class="{ 'bgBlack': bgBlack }">
            <div class="logo">
                <NuxtLink to="/" >
                    <img src="/images/autocolor-logo/autocolor-logo.png" />
                </NuxtLink>
            </div>
            <div class="menu" :class="{ 'justify-content-center' : ($config.public.underConstruction === true) }">
                <div class="divIcon">
                    <NuxtLink to="/qui-somme-nous" >
                        <div class="divIconImg">
                            <img src="/images/header-icon/Groupe 1129/Groupe 1129.png" alt="">
                        </div>
                        <span>Qui sommes nous ?</span>
                    </NuxtLink>
                </div>
                <div class="divIcon" v-if="$config.public.underConstruction === false">
                    <NuxtLink to="#" @click="showMenu('Automobile')">
                        <div class="divIconImg">
                            <img src="/images/header-icon/Groupe 1117/Groupe 1117.png" alt="">
                        </div>
                        <span :class="{ activeMenu: showMenuAutomobile }">Automobile</span>
                        <i class="fa-solid fa-chevron-down" :class="{ show: showMenuAutomobile }"></i>
                    </NuxtLink>
                    <div class="bodyMenu" :class="{ show: show }">
                        <div class="menuList">
                            <div class="principalMenu">
                                <ul>
                                    <li>Réparation</li>
                                    <li class="active">Préparation</li>
                                    <li>Finition</li>
                                    <li>E.P.I</li>
                                    <li>Matériels</li>
                                </ul>
                            </div>
                            <div class="menuItem">
                                <ul>
                                    <li class="active">Abrasifs | Poncage</li>
                                    <li>Adhésifs Techniques</li>
                                    <li>Ruban de masquage</li>
                                    <li>Essuyage</li>
                                    <li>Masquage | Emballage</li>
                                    <li>Plateaux / Cales / Interfaces</li>
                                    <li>Insonorisants | Anti-corrosion</li>
                                    <li>Filtration Peinture</li>
                                </ul>
                            </div>
                            <div class="subMenuItem">
                                <ul>
                                    <li class="active">
                                        <span class="subMenuItemLink" @click="redirectTo()">Coupes</span>
                                    </li>
                                    <li>
                                        <span class="subMenuItemLink" @click="redirectTo()">Disques</span>
                                    </li>
                                    <li>
                                        <span class="subMenuItemLink" @click="redirectTo()">Rouleaux</span>
                                    </li>
                                    <li>
                                        <span class="subMenuItemLink" @click="redirectTo()">Bandes</span>
                                    </li>
                                    <li>
                                        <span class="subMenuItemLink" @click="redirectTo()">Feuilles</span>
                                    </li>
                                    <li>
                                        <span class="subMenuItemLink" @click="redirectTo()">éponges</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="divIcon" v-if="$config.public.underConstruction === false">
                    <NuxtLink to="/test">
                        <div class="divIconImg">
                            <img src="/images/header-icon/Groupe 1119/Groupe 1119.png" alt="">
                        </div>
                        <span>Industrie</span>
                        <i class="fa-solid fa-chevron-down" :class="{ show: showMenuIndustrie }"></i>
                    </NuxtLink>
                </div>
                <div class="divIcon" v-if="$config.public.underConstruction === false">
                    <NuxtLink to="/test">
                        <div class="divIconImg">
                            <img src="/images/header-icon/Groupe 1122/Groupe 1122.png" alt="">
                        </div>
                        <span>Bâtiment</span>
                        <i class="fa-solid fa-chevron-down" :class="{ show: showMenuBatiment }"></i>
                    </NuxtLink>
                </div>
                <div class="divIcon" v-if="$config.public.underConstruction === false">
                    <NuxtLink to="/test">
                        <div class="divIconImg">
                            <img src="/images/header-icon/Groupe 1124/Groupe 1124.png" alt="">
                        </div>
                        <span>Bois</span>
                        <i class="fa-solid fa-chevron-down" :class="{ show: showMenuBois }"></i>
                    </NuxtLink>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
const show = ref(false)

const showMenuAutomobile = ref(false)
const showMenuIndustrie = ref(false)
const showMenuBatiment = ref(false)
const showMenuBois = ref(false)
const bgBlack = ref(false)

const showMenu = (menu) => {
    show.value = !show.value
    switch(menu) {
        case 'Automobile':
            showMenuAutomobile.value = !showMenuAutomobile.value
            break;
        case 'Industrie':
            showMenuIndustrie.value = !showMenuIndustrie.value
            break;
        case 'Batiment':
            showMenuBatiment.value = !showMenuBatiment.value
            break;
        case 'Bois':
            showMenuBois.value = !showMenuBois.value
            break;
    }
}

const redirectTo = () => {
    show.value = false
    showMenuAutomobile.value = showMenuIndustrie.value = showMenuBatiment.value = showMenuBois.value = false
    navigateTo('/product-list')
}

const config = useRuntimeConfig()

onMounted(() => {
  document.addEventListener('scroll', (event) => {
    bgBlack.value = (window.scrollY !== 0)
  })
})

</script>
<style scoped>
.menu img {
    width: 100%;
    height: auto;
}

.header {
    background-image: url("/images/header-fond-noir/Rectangle-578.png");
    display: flex;
    padding: 2rem 8rem 2rem 8rem;
    color: white;
    column-gap: 1rem;
}

.bgBlack {
  background-image: unset;
  background-color: #000 !important;
}

.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}
.logo {
    width: 30%;
}

.divIconImg {
    width: 60px;
}

.divIcon a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
}

.divIcon a span {
    font-family: Barlow;
    text-transform: uppercase;
    font-size: 25px;
}

.bodyMenu {
    position: absolute;
    top: 100%;
    z-index: 9998;
    display: none;
    height: 500px;
    background: #0D0C0C;
    left: 0;
    opacity: 0.9;
    right: 0;
}

.bodyMenu .menuList {
    display: flex;
    justify-content: center;
    column-gap: 2rem;
}

.bodyMenu .menuList ul {
    list-style-type: none;
}

.bodyMenu .menuList ul li {
    font-size: 25px;
    font-family: Barlow;
    text-transform: uppercase;
    padding: .3rem 0;
}

.bodyMenu .menuList .principalMenu ul li {
    border-bottom: 1px solid #fff;
}

.bodyMenu .menuList .principalMenu ul li.active,
.bodyMenu .menuList .menuItem ul li.active {
    border-bottom: 1px solid #FFD11D;
    color: #FFD11D;
}

.bodyMenu .menuList .subMenuItem ul li.active,
.activeMenu {
    color: #FFD11D;
}

.show {
    display: block !important;
}

.fa-chevron-down {
    display: none;
    color: #FFD11D;
}

.search-bar {
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    column-gap: 2rem;
    align-items: center;
    padding: .5rem 10rem 0rem 2rem;
}

.iconSearch {
    width: 25px;
}

.iconCart {
    width: 38px;
}

.search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: .2rem;
}

.search span {
    font-family: Sen;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 600;
}

.become-pro {
    display: flex;
    flex-direction: column;
}

.become-pro span {
    font-family: Barlow;
    font-size: 14px;
}

.subMenuItemLink {
    align-items: flex-start !important;
    cursor: pointer;
}
</style>